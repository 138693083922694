/* eslint-disable max-classes-per-file */
import { io, Socket } from 'socket.io-client';
import { logging } from '@inwink/logging';
import { Store, AnyAction } from 'redux';
import { sendEvent } from '@@data/eventhelpers';
import { States } from './services';
import { eventUserBootstrapModule } from '../routes/appmodules';
import { IRealtimeManager } from './realtimeservice';

const logger = logging.getLogger("Realtime");

interface IRealtimeRegistrationItem {
    ref: any;
}

interface IOwnerRegistration extends IRealtimeRegistrationItem {
    key: string;
}

interface ISpeedMeetingRegistration extends IRealtimeRegistrationItem {
    partitionid: string;
    sessionid: string;
}

interface IEntityRegistration extends IRealtimeRegistrationItem {
    partitionid: string;
    entitykind:string;
    entityid: string;
}

interface IChatRoomRegistration extends IRealtimeRegistrationItem {
    partitionid: string;
    chatkind:string;
    chatid: string;
}

interface IAIRegistration extends IRealtimeRegistrationItem {
    partitionid: string;
    chatkind:string;
    chatid: string;
}

export class RealtimeManager implements IRealtimeManager {
    private _userio: Socket;

    public get userio() : Socket {
        if (!this.initialized) {
            this.init();
        }

        return this._userio;
    }

    private speedMeetings : RealtimeRegistration<ISpeedMeetingRegistration>;

    private owners : RealtimeRegistration<IOwnerRegistration>;

    private chatrooms : RealtimeRegistration<IChatRoomRegistration>;

    private aiChats : RealtimeRegistration<IAIRegistration>;

    private entities : RealtimeRegistration<IEntityRegistration>;

    private disconnectTimeout : any = null;

    private reconnectHandlers: (() => void)[] = [];

    initialized = false;

    constructor(
        public host: string,
        public autoDisconnect: boolean,
        protected store: Store<States.IAppState, AnyAction>
    ) {
        logger.info("Create realtime for " + host);
        this.owners = new RealtimeRegistration<IOwnerRegistration>(
            false,
            store,
            (item) => item.key || "owners",
            () => {
                this.clearDisconnectTimeout();
            },
            () => {
                this.checkDisconnect();
            }
        );
        this.aiChats = new RealtimeRegistration<IAIRegistration>(
            true,
            store,
            (item) => item.partitionid + "#" + item.chatkind + "#" + item.chatid,
            this._joinAIChatRoom,
            this._leaveAIChatRoom
        );
        this.chatrooms = new RealtimeRegistration<IChatRoomRegistration>(
            true,
            store,
            (item) => item.partitionid + "#" + item.chatkind + "#" + item.chatid,
            this._joinChatRoom,
            this._leaveChatRoom
        );
        this.entities = new RealtimeRegistration<IEntityRegistration>(
            true,
            store,
            (item) => item.partitionid + "#" + item.entitykind + "#" + item.entityid,
            this._attachEntity,
            this._detachEntity
        );
        this.speedMeetings = new RealtimeRegistration<ISpeedMeetingRegistration>(
            true,
            store,
            (item) => item.partitionid + "#" + item.sessionid,
            this._joinSpeedMeeting,
            this._leaveSpeedMeeting
        );
    }

    onReconnect(handler: () => void) {
        this.reconnectHandlers.push(handler);
        return () => {
            const idx = this.reconnectHandlers.indexOf(handler);
            if (idx >= 0) {
                this.reconnectHandlers.splice(idx, 1);
            }
        };
    }

    init() {
        if (!this.initialized) {
            this.initialized = true;
            this._userio = io(this.host, {
                transports: ['websocket'],
                reconnectionDelayMax: 60000
            });
            (this._userio as any).nsp = "/companion";
            this.registerEvents();
            this._userio.connect();
        }
    }

    private clearDisconnectTimeout() {
        if (this.disconnectTimeout) {
            clearTimeout(this.disconnectTimeout);
            this.disconnectTimeout = null;
        }
    }

    private disconnectSocket() {
        logger.debug("disconnecting " + this.host);
        this._userio.disconnect();
        this._userio = null;
        this.initialized = false;
    }

    private checkDisconnect() {
        if (this.autoDisconnect && !this.owners.hasItems()) {
            logger.debug("check disconnect true " + this.host);
            this.disconnectTimeout = setTimeout(() => {
                this.disconnectSocket();
                this.clearDisconnectTimeout();
            }, 5000);
        } else {
            logger.debug("check disconnect false " + this.host);
        }
    }

    protected registerEvents() {
        const hostio = this._userio;
        hostio.on("eventnotification", (event) => {
            logger.info("event notification " + this.host, event);
        });

        hostio.on("speedmeeting", (notif) => {
            logger.info("speedmeeting " + this.host, notif);
            if (notif.partition) {
                try {
                    sendEvent(
                        "inwink.speedmeeting."
                            + (notif.partition && notif.partition.toLowerCase()) + "."
                            + (notif.sessionId && notif.sessionId.toLowerCase()),
                        notif
                    );
                } catch (exception) {
                    logger.error("error processing chatroom", exception);
                }
            }
        });

        hostio.on("chatroom", (notif) => {
            logger.info("chatroom " + this.host, notif);
            if (notif.partition) {
                try {
                    sendEvent("inwink.chatroom."
                        + (notif.partition && notif.partition.toLowerCase()) + "."
                        + (notif.chatkind && notif.chatkind.toLowerCase()) + "."
                        + (notif.chatid && notif.chatid.toLowerCase()), notif.data);
                } catch (exception) {
                    logger.error("error processing chatroom", exception);
                }
            }
        });

        hostio.on("aichat", (notif) => {
            logger.info("aichat " + this.host, notif);
            if (notif.partition) {
                try {
                    sendEvent("inwink.aichat."
                        + (notif.partition && notif.partition.toLowerCase()) + "."
                        + (notif.chatkind && notif.chatkind.toLowerCase()) + "."
                        + (notif.chatid && notif.chatid.toLowerCase()), notif.data);
                } catch (exception) {
                    logger.error("error processing aichat", exception);
                }
            }
        });

        hostio.on("entity", (notif) => {
            logger.info("entity " + this.host, notif);
            if (notif.partition) {
                try {
                    const key = "inwink.entity."
                    + (notif.partition && notif.partition.toLowerCase()) + "."
                    + (notif.entitykind && notif.entitykind.toLowerCase()) + "."
                    + (notif.entityid && notif.entityid.toLowerCase());
                    sendEvent(key, notif.data);
                } catch (exception) {
                    logger.error("error processing entity notification", exception);
                }
            }
        });

        hostio.on("exhibitornotification", (notif) => {
            logger.info("exhibitornotification " + this.host, notif);
            if (notif.exhibitorId) {
                try {
                    sendEvent("inwink.exhibitornotification." + notif.exhibitorId, notif);
                } catch (exception) {
                    logger.error("error processing exhibitornotification", exception);
                }
            }
        });

        hostio.on("livesessionstart", (livestate) => {
            try {
                logger.debug("livesession start " + livestate.liveSessionId + " " + this.host);
                sendEvent("inwink.livesession.start." + livestate.liveSessionId, livestate);
            } catch (exception) {
                logger.error("error processing live session state", exception);
            }
        });

        hostio.on("livesessionstate", (livestate) => {
            try {
                logger.debug("livesession state " + this.host, livestate);
                sendEvent("inwink.livesession." + livestate.liveSessionId, livestate);
            } catch (exception) {
                logger.error("error processing live session state", exception);
            }
        });

        hostio.on("eventmessagesnotification", (state) => {
            try {
                logger.debug("eventmessages notification" + this.host, state);
                sendEvent("inwink.eventmessages." + state.eventId, state);
            } catch (exception) {
                logger.error("error processing eventmessages state", exception);
            }
        });

        hostio.on("sessionmessagesnotification", (state) => {
            try {
                logger.debug("sessionmessages notification" + this.host, state);
                sendEvent("inwink.sessionmessages." + state.eventId + "." + state.sessionId, state);
            } catch (exception) {
                logger.error("error processing sessionmessages state", exception);
            }
        });

        hostio.on("sessionlivesessionnotification", (state) => {
            try {
                logger.debug("sessionpolls notification" + this.host, state);
                sendEvent("inwink.sessionpolls." + state.eventId + "." + state.sessionId, state);
            } catch (exception) {
                logger.error("error processing sessionmessages state", exception);
            }
        });

        hostio.on("usernotification", (notifdata) => {
            logger.info("user notification " + this.host, notifdata);
            const state = this.store.getState();
            if (state.event?.eventid) {
                if (notifdata && notifdata.Type === "NewDiscussionThreadMessage") {
                    eventUserBootstrapModule().then((mod) => {
                        mod.userSyncActions.syncDiscussionThreads(true)(this.store.dispatch, this.store.getState);
                    });
                } else {
                    eventUserBootstrapModule().then((mod) => {
                        mod.userSyncActions.lightSyncCurrentUser(true)(this.store.dispatch, this.store.getState);
                    });
                }
            } else if (state.community?.communityid) {
                try {
                    const eventname = "inwink." + state.community.communityid + "." + notifdata.type;
                    logger.debug("community user notification" + this.host, eventname, notifdata);
                    sendEvent(eventname, notifdata);
                } catch (exception) {
                    logger.error("error processing sessionmessages state", exception);
                }
            }
        });

        hostio.on("discussionnotification", (event) => {
            logger.info("discussionnotification " + this.host, event);
            try {
                logger.debug("discussionnotification", event);
                sendEvent("inwink.discussion." + event.discussionId, event);
            } catch (exception) {
                logger.error("error processing sessionmessages state", exception);
            }
        });

        hostio.on("discussiontyping", (notif) => {
            logger.info("discussiontyping " + this.host, notif);
            try {
                sendEvent("inwink.discussion." + notif.discussionid + ".typing", notif);
            } catch (exception) {
                logger.error("error processing exhibitornotification", exception);
            }
        });

        hostio.on("connect", () => {
            logger.info("connect realtime " + this.host);
            this.onreconnectSocket();
        });

        hostio.on("reconnect", (event) => {
            logger.info("reconnect realtime " + this.host, event);
            this.onreconnectSocket();
        });

        hostio.on("error", (event) => {
            logger.error("error " + this.host, event);
        });

        hostio.on("connect_error", (event) => {
            logger.warn("connect error " + this.host, event);
        });

        hostio.on("reconnect_error", (event) => {
            logger.warn("reconnect error " + this.host, event);
        });
    }

    registerEvent(eventid) {
        if (InWinkPreview) {
            logger.debug("register for event preview realtime");
            this.userio.emit("joineventpreview", {
                eventid: eventid
            });
        } else {
            logger.debug("register for event realtime");
            this.userio.emit("joinevent", {
                eventid: eventid
            });
        }
    }

    registerUser(eventid) {
        const state = this.store.getState();
        if (state.user.currentUser && state.user.currentUser.detail) {
            const exhibitorIds = state.user.currentUser
                && state.user.currentUser.detail
                && state.user.currentUser.detail.exhibitorAccounts
                && state.user.currentUser.detail.exhibitorAccounts.map((ea) => ea.exhibitorId);

            logger.debug("register for user realtime");
            this.userio.emit("login", {
                eventid: eventid,
                userid: (state.user.currentUser.detail as any).id,
                iwuserid: (state.user.currentUser.detail as any).userId,
                jwttoken: (state.user.currentUser.detail as any).inwinkrealtimetoken,
                isPreview: InWinkPreview,
                exhibitorids: exhibitorIds
            });
        } else {
            this.registerEvent(eventid);
        }
    }

    joinEventDiscussion(discussionid) {
        const state = this.store.getState();
        if (state.user.currentUser && state.user.currentUser.detail) {
            const partitionid = state.event?.eventid || state.community?.communityid;
            const partitiontype = state.event?.eventid
                ? 'event'
                : (state.community?.communityid) ? 'community' : null;

            this.userio.emit("joindiscussion", {
                partitionid,
                partitiontype,
                eventid: partitionid,
                discussionid: discussionid,
                userid: (state.user.currentUser.detail as any).id,
                iwuserid: (state.user.currentUser.detail as any).userId,
                jwttoken: (state.user.currentUser.detail as any).inwinkrealtimetoken,
            });
        }
    }

    leaveEventDiscussion(discussionid) {
        const state = this.store.getState();
        if (state.user.currentUser && state.user.currentUser.detail) {
            const partitionid = state.event?.eventid || state.community?.communityid;
            const partitiontype = state.event?.eventid
                ? 'event'
                : (state.community?.communityid) ? 'community' : null;

            this.userio.emit("leavediscussion", {
                partitionid,
                partitiontype,
                eventid: partitionid,
                discussionid: discussionid,
                userid: (state.user.currentUser.detail as any).id,
                iwuserid: (state.user.currentUser.detail as any).userId,
                jwttoken: (state.user.currentUser.detail as any).inwinkrealtimetoken,
            });
        }
    }

    eventDiscussionTyping(eventid: string, userid: string, discussionid: string, typing: boolean) {
        this.userio.emit("typing", {
            eventid: eventid,
            userid: userid,
            discussionid: discussionid,
            typing: typing
        });
    }

    joinLive(liveid) {
        const state = this.store.getState();
        this.userio.emit("joinlive", {
            eventid: state.event?.eventid,
            userid: state.user.currentUser && state.user.currentUser.detail && state.user.currentUser.detail.id,
            liveid: liveid
        });
    }

    leaveLive(liveid) {
        const state = this.store.getState();
        this.userio.emit("leavelive", {
            eventid: state.event?.eventid,
            userid: state.user.currentUser && state.user.currentUser.detail && state.user.currentUser.detail.id,
            liveid: liveid
        });
    }

    checkLiveState(eventid, liveid) {
        this.userio.emit("checklivestate", {
            eventid: eventid,
            liveid: liveid
        });
    }

    joinSpeedMeeting(owner: any, partitionid: string, sessionid: string) {
        return this.speedMeetings.attachItem({
            ref: owner,
            partitionid: partitionid,
            sessionid: sessionid
        });
    }

    leaveSpeedMeeting(owner: any, partitionid: string, sessionid: string) {
        return this.speedMeetings.detachItem({
            ref: owner,
            partitionid: partitionid,
            sessionid: sessionid
        });
    }

    private _joinSpeedMeeting = (item: ISpeedMeetingRegistration, key: string) => {
        this.owners.attachItem({ ref: item.ref, key });
        const state = this.store.getState();
        this.userio.emit("joinspeedmeeting", {
            eventid: item.partitionid,
            sessionid: item.sessionid,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    };

    private _leaveSpeedMeeting = (item: ISpeedMeetingRegistration, key: string) => {
        this.owners.detachItem({ ref: item.ref, key });
        const state = this.store.getState();
        this.userio.emit("leavespeedmeeting", {
            eventid: item.partitionid,
            sessionid: item.sessionid,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    };

    joinRoundTable(eventid: string, roundTableId: string, participationId: string) {
        const state = this.store.getState();
        this.userio.emit("joinroundtable", {
            eventid: eventid,
            roundTableId: roundTableId,
            participationId: participationId,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    }

    leaveRoundTable(eventid: string, roundTableId: string, participationId: string) {
        const state = this.store.getState();
        this.userio.emit("leaveroundtable", {
            eventid: eventid,
            roundTableId: roundTableId,
            participationId: participationId,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    }

    joinWatchTogether(eventid: string, watchTogetherId: string, participationId: string) {
        const state = this.store.getState();
        this.userio.emit("joinwatchtogether", {
            eventid: eventid,
            watchTogetherId: watchTogetherId,
            participationId: participationId,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    }

    leaveWatchTogether(eventid: string, watchTogetherId: string, participationId: string) {
        const state = this.store.getState();
        this.userio.emit("leavewatchtogether", {
            eventid: eventid,
            watchTogetherId: watchTogetherId,
            participationId: participationId,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    }

    joinMeeting(eventid: string, meetingId: string) {
        const state = this.store.getState();
        this.userio.emit("joinmeeting", {
            eventid: eventid,
            meetingId: meetingId,
            personId: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    }

    leaveMeeting(eventid: string, meetingId: string) {
        const state = this.store.getState();
        this.userio.emit("leavemeeting", {
            eventid: eventid,
            meetingId: meetingId,
            personId: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    }

    private _joinChatRoom = (item: IChatRoomRegistration, key: string) => {
        this.owners.attachItem({ ref: item.ref, key });
        logger.debug("join chat room " + item.chatkind + "#" + item.chatid + " " + this.host);
        const state = this.store.getState();
        const partitionid = state.event?.eventid || state.community?.communityid;
        const partitiontype = state.event?.eventid
            ? 'event'
            : (state.community?.communityid) ? 'community' : null;

        this.userio.emit("joinchatroom", {
            partitionid,
            partitiontype,
            eventid: item.partitionid,
            chatkind: item.chatkind,
            chatid: item.chatid,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    };

    private _leaveChatRoom = (item: IChatRoomRegistration, key: string) => {
        this.owners.detachItem({ ref: item.ref, key });
        logger.debug("leave chat room " + item.chatkind + "#" + item.chatid + " " + this.host);
        const state = this.store.getState();
        const partitionid = state.event?.eventid || state.community?.communityid;
        const partitiontype = state.event?.eventid
            ? 'event'
            : (state.community?.communityid) ? 'community' : null;

        this.userio.emit("leavechatroom", {
            partitionid,
            partitiontype,
            eventid: item.partitionid,
            chatkind: item.chatkind,
            chatid: item.chatid,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    };


    private _attachEntity = (item: IEntityRegistration, key: string) => {
        this.owners.attachItem({ ref: item.ref, key });
        logger.debug("attach entity " + item.entitykind + "#" + item.entityid + " " + this.host);
        const state = this.store.getState();
        const partitionid = state.event?.eventid || state.community?.communityid;
        const partitiontype = state.event?.eventid
            ? 'event'
            : (state.community?.communityid) ? 'community' : null;

        this.userio.emit("attachentity", {
            partitionid,
            partitiontype,
            eventid: item.partitionid,
            entitykind: item.entitykind,
            entityid: item.entityid,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    };

    private _detachEntity = (item: IEntityRegistration, key: string) => {
        this.owners.detachItem({ ref: item.ref, key });
        logger.debug("detach entity " + item.entitykind + "#" + item.entityid + " " + this.host);
        const state = this.store.getState();
        const partitionid = state.event?.eventid || state.community?.communityid;
        const partitiontype = state.event?.eventid
            ? 'event'
            : (state.community?.communityid) ? 'community' : null;

        this.userio.emit("detachentity", {
            partitionid,
            partitiontype,
            eventid: item.partitionid,
            entitykind: item.entitykind,
            entityid: item.entityid,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    };

    attachOwner(owner: any) {
        return this.owners.attachItem({ ref: owner, key: null });
    }

    detachOwner(owner: any) {
        this.owners.detachItem({ ref: owner, key: null });
    }

    attachEntity(owner: any, partitionid: string, entity: string, entityid: string) {
        return this.entities.attachItem({
            ref: owner,
            partitionid: partitionid,
            entitykind: entity,
            entityid: entityid
        });
    }

    detachEntity(owner: any, partitionid: string, entity: string, entityid: string) {
        return this.entities.detachItem({
            ref: owner,
            partitionid: partitionid,
            entitykind: entity,
            entityid: entityid
        });
    }

    joinChatroom(owner: any, partitionid: string, chatkind: string, chatid: string) {
        return this.chatrooms.attachItem({
            ref: owner,
            partitionid: partitionid,
            chatkind: chatkind,
            chatid: chatid
        });
    }

    leaveChatroom(owner: any, partitionid: string, chatkind: string, chatid: string) {
        return this.chatrooms.detachItem({
            ref: owner,
            partitionid: partitionid,
            chatkind: chatkind,
            chatid: chatid
        });
    }

    joinAi(owner: any, partitionid: string, sessionid: string, chatkind: string) {
        return this.aiChats.attachItem({
            ref: owner,
            partitionid: partitionid,
            chatkind: chatkind,
            chatid: sessionid
        });
    }

    leaveAi(owner: any, partitionid: string, sessionid: string, chatkind: string) {
        return this.aiChats.detachItem({
            ref: owner,
            partitionid: partitionid,
            chatkind: chatkind,
            chatid: sessionid
        });
    }

    
    private _joinAIChatRoom = (item: IAIRegistration, key: string) => {
        this.owners.attachItem({ ref: item.ref, key });
        logger.debug("join ai chat room " + item.chatkind + "#" + item.chatid + " " + this.host);
        const state = this.store.getState();
        const partitionid = state.event?.eventid || state.community?.communityid;
        const partitiontype = state.event?.eventid
            ? 'event'
            : (state.community?.communityid) ? 'community' : null;

        this.userio.emit("joinaichat", {
            partitionid,
            partitiontype,
            eventid: item.partitionid,
            chatkind: item.chatkind,
            chatid: item.chatid,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    };

    private _leaveAIChatRoom = (item: IAIRegistration, key: string) => {
        this.owners.detachItem({ ref: item.ref, key });
        logger.debug("leave ai chat room " + item.chatkind + "#" + item.chatid + " " + this.host);
        const state = this.store.getState();
        const partitionid = state.event?.eventid || state.community?.communityid;
        const partitiontype = state.event?.eventid
            ? 'event'
            : (state.community?.communityid) ? 'community' : null;

        this.userio.emit("leaveaichat", {
            partitionid,
            partitiontype,
            eventid: item.partitionid,
            chatkind: item.chatkind,
            chatid: item.chatid,
            userid: (state.user.currentUser?.detail as any)?.id,
            iwuserid: (state.user.currentUser?.detail as any)?.userId,
            jwttoken: (state.user.currentUser?.detail as any)?.inwinkrealtimetoken,
        });
    };

    protected onreconnectSocket() {
        const store = this.store;

        const state = store.getState();
        if (state.event?.eventid && state.user?.currentUser) {
            this.registerUser(state.event.eventid);
        } else if (state.event?.eventid) {
            this.registerEvent(state.event.eventid);
        } else if (state.rootwebsite?.websiteid) {
            this.registerEvent(state.rootwebsite.websiteid);
        }

        this.chatrooms.reconnect();
        this.entities.reconnect();
        this.speedMeetings.reconnect();
        this.aiChats.reconnect();

        this.reconnectHandlers.forEach((handler) => {
            handler();
        });

        sendEvent("inwink.realtime.reconnect", {});
    }
}

class RealtimeRegistration<T extends IRealtimeRegistrationItem> {
    private items : Record<string, T[]> = {};

    constructor(
        private requireUser: boolean,
        private store: Store<States.IAppState, AnyAction>,
        private getKey: (item: T) => string,
        private attach: (item: T, key: string) => void,
        private detach: (item: T, key: string) => void,
    ) {
    }

    hasItems() {
        let hasItems = false;
        const items = Object.values(this.items);
        items.forEach((roomRegistrations) => {
            if (roomRegistrations && roomRegistrations.length) {
                hasItems = true;
            }
        });

        return hasItems;
    }

    reconnect() {
        const itemsKeys = Object.keys(this.items);
        itemsKeys.forEach((roomRegistrationsKey) => {
            const roomRegistrations = this.items[roomRegistrationsKey];
            if (roomRegistrations && roomRegistrations.length) {
                const registration = roomRegistrations[0];
                this.attach(registration, roomRegistrationsKey);
            }
        });
    }

    attachItem(item: T) {
        if (this.requireUser) {
            const state = this.store.getState();
            if (!state.user.currentUser?.detail) {
                // eslint-disable-next-line no-debugger
                debugger;
                console.warn("attachEntity require authenticated user");
                return () => {

                };
            }
        }

        const key = this.getKey(item);
        const items = this.items;
        let registrations = items[key];
        if (!registrations) {
            registrations = [];
            items[key] = registrations;
        }

        const existing = registrations.find((r) => r.ref === item.ref);
        if (!existing) {
            registrations.push(item);
        }

        this.attach(item, key);

        return () => {
            this.detachItem(item);
            // this.detach(item, key);
        };
    }

    detachItem(item: T) {
        const key = this.getKey(item);
        const items = this.items || {};
        let registrations = items[key];
        const existing = registrations && registrations.find((r) => r.ref === item.ref);
        if (existing) {
            registrations = [...registrations];

            const idx = registrations.indexOf(existing);
            registrations.splice(idx, 1);
        }

        if (!registrations || !registrations.length) {
            delete items[key];
            this.detach(item, key);
        } else {
            items[key] = registrations;
        }
    }
}
