export function sendEvent(eventname, data) {
    console.log("trigger event for " + eventname);
    try {
        if (typeof (window as any).CustomEvent === "function") {
            const domevent = new CustomEvent(eventname, { detail: data, bubbles: true, cancelable: true });
            document.body.dispatchEvent(domevent);
        } else if (document.createEvent) {
            const evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(eventname, true, true, data);
        }
    } catch (ex) {
        console.error("cannot dispatch event " + eventname);
    }
}
